import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Chip from '@material-ui/core/Chip'

import LaptopMacIcon from '@material-ui/icons/LaptopMac'
import SchoolIcon from '@material-ui/icons/School'
import CloudDoneIcon from '@material-ui/icons/CloudDone'
import WorkIcon from '@material-ui/icons/Work'
import LaptopWindowsIcon from '@material-ui/icons/LaptopWindows'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: '15px',
  },
  seperator: {
    height: 'auto',
  },
  time: {
    fontSize: '20px',
  },
  inst: {
    textAlign: 'left',
    fontSize: '16px',
  },
  edu: {
    textAlign: 'left',
    fontSize: '14px',
  },
  skills: {
    textAlign: 'left',
  },
  list: {
    margin: '0px',
    textAlign: 'left',
    fontSize: '14px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}))

export default function MyTimeline() {
  const classes = useStyles()

  return (
    <Timeline align="alternate" style={{ margin: '0px', padding: '0px' }}>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>May 2022 - Current</Typography>
            <Typography className={classes.inst}>
              TATA Digital, Bengaluru
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {['KTOR', 'Azure', 'Sanity', 'K8', 'Next'].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li> Designed and implemented a scalable micro-app kubernetes powered system, ensuring alignment
                  with business requirements and budgets.</li>
                <li> Designed secure technical solutions for functional and non-functional requirements, that met
                  business needs, product delivery timelines, InfoSec clearance & Infra guidelines. </li>
                <li> Led cross-functional teams consisting of UX, UI, API & QA, in translating high-level technical goals
                  into clear, actionable design plans and releases. </li>
                <li> Enforced best practices and coding standards, enhancing code quality and system reliability.</li>
                <li> Provided coaching and mentorship to junior developers, fostering a culture of continuous learning
                  and improvement.</li>
                <li> Evaluated and integrated new tools, frameworks & vendors. Showcased POCs for optimizations and
                  quick decisions. </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>September 2020 - May 2022</Typography>
            <Typography className={classes.inst}>
              Idyllic Co, Remote
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {['Ruby on Rails', 'React', 'Docker', 'CapRover', 'Digital Ocean'].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li>HuntWallet : Developed the backend APIs for the application and deployed it in AWS using EC2, RDS and S3</li>
                <li>
                  Extending existing open source Rails application and add an analytics engine onto it
                </li>
                <li>Integrated a one time code to the signup process </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <CloudDoneIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>August 2020</Typography>
            <Typography className={classes.inst}>
              Google Cloud Platform
            </Typography>
            <Typography className={classes.edu}>
              Google{' '}
              <a
                href="https://www.credential.net/6a3cc40f-f270-49b3-b395-601ee32e82ad#gs.dxdxuv"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
              >
                Certified
              </a>{' '}
              Associate Cloud Engineer
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {['Cloud Engineer'].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              August 2019 - May 2020
            </Typography>
            <Typography className={classes.inst}>
              The Talent Enterprise, Bengaluru
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {['Ruby on Rails', 'React', 'Git', 'Heroku'].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li>Improved accuracy of search results</li>
                <li>
                  Enabled seamless sharing of unique urls through QR codes
                </li>
                <li>Integrated a one time code to the signup process </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <LaptopWindowsIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              May 2016 - July 2019
            </Typography>
            <Typography className={classes.inst}>
              Fresco, TCS, Chennai
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {[
                'Ruby on Rails',
                'React Native',
                'Git',
                'Django',
                'NightWatch.js',
              ].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li>
                  Fixed and automated a rails application which was then used as
                  an app store
                </li>
                <li>
                  POC demo : a socket powered online game (similar to PokemonGo)
                </li>
                <li>Developed APIs for an interactive learning platform</li>
                <li>
                  Integrated various vendors and services with the said learning
                  platform
                </li>
                <li>Developed a REST API based micro app in Django</li>
                <li>
                  Wrote functional tests using NightWatch.js to enable scoring
                  on a hackathon
                </li>
                <li>
                  Converted an Ionic app into a React Native app and deployed to
                  production
                </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <WorkIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              July 2014 - May 2016
            </Typography>
            <Typography className={classes.inst}>
              iGnite (L&amp;D), TCS, Chennai
            </Typography>
            <Typography className={classes.skills}>
              <span>Skills :</span>
              {[
                'HTML5',
                'CSS3',
                'Javscript',
                'Java',
                'J2EE',
                '3D modelling',
                'AIML',
              ].map(skill => (
                <Chip
                  label={skill}
                  variant="outlined"
                  color="primary"
                  size="small"
                  style={{
                    margin: '2px',
                    marginBottom: '4px',
                    fontSize: '10px',
                  }}
                />
              ))}
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li>
                  Research for modules of an UAV (Multispectral imaging and
                  Digital Surface Elevation Model)
                </li>
                <li>
                  Led the development for a crowdsourcing app for android and
                  web
                </li>
                <li>
                  Soft skill &amp; technical training imparted to multiple
                  batches of freshers
                </li>
                <li>Trained a Chat Bot to be used for different scenarios </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              June 2011 - May 2014
            </Typography>
            <Typography className={classes.inst}>
              BSc Information &amp; Technology
            </Typography>
            <Typography className={classes.edu}>
              Ravenshaw University, Cuttack, Odisha
            </Typography>
            <Typography>
              <ul className={classes.list}>
                <li>Hons. in software design</li>
                <li>C, DS, DBMS, C++, VB.Net, Java, C#</li>
                <li>Servers &amp; Operating systems (Linux)</li>
                <li>
                  Conducted a marathon for all age groups in my town, to promote
                  fitness as a habit.
                </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              May 2009 - April 2011
            </Typography>
            <Typography className={classes.inst}>
              Kendriya Vidyalaya, Cuttack, Odisha
            </Typography>
            <Typography className={classes.edu}>Senior School</Typography>
            <Typography>
              <ul className={classes.list}>
                <li>Java &amp; C++</li>
                <li>Advanced STEM</li>
                <li>
                  Transition from cricket to football as a way of life. #GGMU
                </li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator className={classes.seperator}>
          <TimelineDot color="primary" variant="outlined">
            <SchoolIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent>
          <Paper elevation={3} className={classes.paper}>
            <Typography className={classes.time}>
              May 1999 - April 2009
            </Typography>
            <Typography className={classes.inst}>
              Stewart School, Cuttack, Odisha
            </Typography>
            <Typography className={classes.edu}>Secondary School</Typography>
            <Typography>
              <ul className={classes.list}>
                <li>C &amp; LOGO language</li>
                <li>STEM 101</li>
                <li>Communication skills</li>
              </ul>
            </Typography>
          </Paper>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )
}
